// import packages
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Link, NavLink, useLocation} from "react-router-dom";

//import assets
import './header.scss'
import {ArrowLinkIcon, BurgerMenuIcon, CloseMenuIcon, Logo} from "../../assets/images";

// Import Components
import {Dropdown, Menu} from "antd";

// Import utils
import {getPropsFromState} from "../../redux/mapStateToProps";
import {ChangeLanguage} from "../../redux/actions";
import {getUrlWithLocal} from "../../utils/getUrlWithLocal";
import {history} from "../../configs/history";
import {generateImageMediaUrl} from "../../utils/generateMediaUrl";

function Header(props) {
    const {staticTexts,selectedLanguage,languages,toggleLanguage,mobileMenuOpen,toggleMobileMenu} = props;
    const [closeLanguage, setCloseLanguage] = useState(false)
    const location = useLocation()
    useEffect(() => {
        window.addEventListener("scroll",() => setCloseLanguage(true))
    },[])

    const items = [
        {
            key: '1',
            label: (
                <a target="_blank" rel="noopener noreferrer" href="https://www.antgroup.com">
                    1st menu item
                </a>
            ),
        },
    ];

    const menuLanguage = (
        <Menu className={`languages-dropdown`}>
            {
                languages?.length && languages?.map((item) => {
                    return selectedLanguage?.code !== item.code &&
                        <Menu.Item key={item.id}
                                   onClick={() => {
                                       history.push(`/${item.code}${history.location.pathname.slice(3)}`)
                                       props.ChangeLanguage(item)
                                   }}
                                   className={'language-dropdown-item'}>
                            <div className="selected-language">
                                <span>{item?.name}</span>
                            </div>
                        </Menu.Item>
                })
            }
        </Menu>
    );

const isHome = location.pathname === getUrlWithLocal('/')
    // console.log('location', location.pathname, getUrlWithLocal('/'));
    return <div className={`header-wrapper ${mobileMenuOpen ? 'responsive' : ''} ${isHome ? '' : 'light'}`}>
        <div className={'header-block-wrapper'}>
            <div className={'link-menu'}>
                <Link to={getUrlWithLocal('/')} className={'hybrid-logo'}>
                    <Logo title={''}/>
                </Link>
                <NavLink to={getUrlWithLocal('/about')} activeClassName='active'>
                    <li className="nav-item">{staticTexts?.header_item_about}</li>
                </NavLink>
                <NavLink to={getUrlWithLocal('/services')} activeClassName='active'>
                    <li className="nav-item">{staticTexts?.header_item_services}</li>
                </NavLink>
                <NavLink to={getUrlWithLocal('/info')} activeClassName='active'>
                    <li className="nav-item">{staticTexts?.header_item_info}</li>
                </NavLink>
            </div>
            <ul className="nav-menu">
                <NavLink to={getUrlWithLocal('/contact')} className='contact-block'>
                    <li className="telematic-text">{staticTexts?.header_item_contact}</li>
                    <ArrowLinkIcon/>
                </NavLink>

                <li className="nav-item">
                    <Dropdown overlay={menuLanguage}
                              trigger={['click']}
                              overlayClassName={`${closeLanguage ? "close" : ""}`}
                              onClick={() => toggleLanguage(setCloseLanguage(false))}
                              placement={'bottom'}>
                        <a className="ant-dropdown-link">
                            <div className="selected-language">
                                {/*<span>{selectedLanguage?.name}</span>*/}
                                <div className={'language_icon'}>
                                    <img src={generateImageMediaUrl(selectedLanguage?.icon?.path)}/>
                                </div>
                                <div>
                                </div>
                            </div>
                        </a>
                    </Dropdown>
                </li>
                <a className={`header_burger ${!mobileMenuOpen ? 'open-menu' : 'close-menu'}`}
                   onClick={toggleMobileMenu}>
                    {!mobileMenuOpen ? <BurgerMenuIcon/> : <CloseMenuIcon/>}
                </a>
            </ul>
        </div>
    </div>
}

const mapStateToProps = (state) => {
    return getPropsFromState(state, [
        'staticTexts',
        'languages',
        'selectedLanguage',
        'services',
    ])
};
const mapDispatchToProps = {ChangeLanguage};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
